import React from 'react';

import Page from "../components/Page";
import Features4 from '../components/features/Features4';
import Pricing3 from '../components/pricing/Pricing3';
import Team2 from '../components/team/Team2';
import Header1 from "../components/headers/Header1";

export default function Index() {
  return (
    <Page>
      <Header1
        content={{
          'header-p1': 'Bux Ninja',
          'header-p2': 'the mobile Bitcoin SV wallet for Bux servers',
          description: 'Empower your financial freedom with the lightning-fast and incredibly versatile mobile and app wallet solution for Bitcoin SV.'
        }}
      />

      <Features4
        content={{
          badge: 'There is more',
          'header-p1': 'Bux Ninja',
          'header-p2': 'offers a suite of services',
          description:
            'Professional and reliable services for you and your Bitcoin applications. Built on open source software using open protocols.',
          'col1-header': 'Mobile wallet',
          'col1-desc':
            "The Bux Ninja mobile wallet allows a user to connect to multiple accounts and manage their Bitcoin.",
          'col2-header': 'Transactions processing',
          'col2-desc':
            'Let us handle the work of getting your transactions into the blockchain. Never have another transaction getting lost.',
          'col3-header': 'Wallet services',
          'col3-desc':
            "Use our REST and GraphQL API's to easily built a wallet backend for your applications.",
          'col4-header': 'Enterprise grade',
          'col4-desc':
            'Our services all adhere to the highest industry standards and are regularly audited by third parties.',
        }}
      />

      <Pricing3
        content={{
          badge: 'The financial stuff',
          'header-p1': 'Wallet service',
          'header-p2': "pricing that doesn't break the bank",
          description:
            "We're here to serve, so let us know if there are other needs you might have.",
          '01_title': 'Personal',
          '01_price': '$0',
          '01_benefit1': '1 account',
          '01_benefit2': 'Fair use transactions',
          '01_benefit3': 'Free @bux.ninja paymail',
          '01_benefit4': 'Community support',
          '02_title': 'Developer',
          '02_price': '$49',
          '02_benefit1': 'Unlimited accounts',
          '02_benefit2': 'Fair use transactions',
          '02_benefit3': 'Custom domain paymail',
          '02_benefit4': 'Community support',
          '03_title': 'Professional',
          '03_price': '$249',
          '03_benefit1': 'Unlimited accounts',
          '03_benefit2': 'Fair use transactions',
          '03_benefit3': 'Custom domain paymail',
          '03_benefit4': 'Priority support',
          '04_price': '',
          '04_title': 'Enterprise',
          '04_suffix': 'Contact us for pricing',
          '04_benefit1': 'Unlimited accounts',
          '04_benefit2': 'Unlimited transactions',
          '04_benefit3': 'Custom domain paymails',
          '04_benefit4': 'Enterprise support / SLA',
        }}
      />

      {/*
      <Team2
        content={{
          badge: 'The people that do it all',
          'header-p1': 'Meet the team',
          'header-p2': 'that brings you all the awesome',
          description: 'They are the hard working ninjas making sure everything works for you.',
          '01_name': 'Siggi',
          '02_name': 'Steef',
          '03_name': 'Kjartan',
          '03_job': 'Developer',
          '04_name': 'Skotti',
          '04_job': 'Mascott',
        }}
      />
      */}
    </Page>
  );
}

