import React from 'react';
import { useHistory } from "react-router-dom";

import Page from "../components/Page";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    marginBottom: theme.spacing(3)
  }
}));

export default function Signup() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Page>
      <section>
        <Container maxWidth="sm">
          <Box pt={6} pb={10} textAlign="center">
            <img src="/images/illustrations/loading.svg" alt="" className={classes.img} />
            <Typography variant="h3" color="primary">
              Under construction
            </Typography>
            <Typography variant="h3" component="h2" gutterBottom={true}>
              This page has not yet been created
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              We will hurry up to finish this page
            </Typography>
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push('/');
                }}
              >
                Return to the homepage
              </Button>
            </Box>
          </Box>
        </Container>
      </section>
    </Page>
  )
};
