import React from 'react';
import { useHistory } from "react-router-dom";

import Page from "../components/Page";
import makeStyles from "@material-ui/core/styles/makeStyles";

import Header8 from '../components/headers/Header8';
import Features8 from '../components/features/Features8';
import CallToAction2 from '../components/call-to-action/CallToAction2-mobile';
import HowItWorks4 from '../components/how-it-works/HowItWorks4';


const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    marginBottom: theme.spacing(3)
  }
}));

export default function MobileWallet() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Page>
      <section>
        <Header8
          content={{
            'header-p1': 'Bux Ninja app',
            'header-p2': 'non-custodial Bitcoin SV mobile wallet',
            description:
              'The Bux Ninja mobile app is a non-custodial Bitcoin wallet that gives you full control over your private keys.\u00a0',
            image: '/images/screen-txs.png',
          }}
        />

        <Features8
          content={{
            'col1-header': 'Send Bitcoin to any wallet',
            'col1-desc':
              "With the Bux Ninja mobile wallet you can send Bitcoin to any other wallet on the network. Whether you are sending to legacy addresses or to a paymail, we've got you covered.",
            'col2-header': 'Receive Bitcoin from anyone',
            'col2-desc':
              'Bux servers are listening to all the transactions that are being sent on the networl, and therefore support receiving on either a legacy adress or on your paymail address.',
            'col3-header': 'Full view of all your transactions',
            'col3-desc':
              'You will always have access to all your transactions in the mobile app, including full details and any other (meta) data that was added with the transaction.',
            'col4-header': 'Non-custodial wallet',
            'col4-desc':
              'You are in control of your private keys. Create an extended (HD) private key with a seed phrase (24 words) or import your existing xprv.',
            'col5-header': 'Full paymail support',
            'col5-desc':
              'Bux servers support paymail, and sending and recieving transactions from any paymail provider. You can even create your own @bux.ninja paymail for free.',
            'col6-header': 'Fully backed up infrastructure',
            'col6-desc':
              'Experience unparalleled security and peace of mind with our enterprise-grade database storage solution, ensuring complete accessibility to your transaction history at all times.',
          }}
        />

        <CallToAction2
          content={{
            description:
              'Install Bux Ninja now and take back control of your Bitcoin SV private keys. Available for iOS and Android. Coming soon!',
            'primary-action': 'Apple',
            'secondary-action': 'Android (soon)',
          }}
        />

        <HowItWorks4
          content={{
            'header-p1': 'Bux Ninja',
            'description': 'Some of the features offered by the Bux Ninja mobile wallet.',
            'header-p2': 'app features and possibilities',
            'col1-image': '/images/screen-txs.png',
            'col1-header': 'Full view of all transactions',
            'col1-desc':
              'See a full list of all sent and received transactions that were sent through the bux server you are connected to.',
            'col2-image': '/images/screen-received.png',
            'col2-header': 'View transaction details',
            'col2-desc':
              'Every transactions has a detail view where more details can be seen.',
            'col3-image': '/images/screen-manual.png',
            'col3-header': 'Connect to any Bux server',
            'col3-desc':
              'You can connect to any Bux server with Bux Ninja. Just fill in your seed phrase or xprv, which will be stored securely on your device',
          }}
        />
      </section>
    </Page>
  )
};
