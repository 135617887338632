import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundImage: 'url("nereus-assets/img/bg/pattern2.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.dark,
  },
  description: {
    color: theme.palette.background.secondary
  },
  actions: {
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
    }
  },
  primaryAction: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    }
  },
}));

export default function CTA(props) {
  const classes = useStyles();

  const content = {
    'description': 'Suspendisse aliquam tellus ante, porttitor mattis diam eleifend quis. Pellentesque pulvinar commodo eros sit amet finibus.',
    ...props.content
  };

  return (
    <section className={classes.section}>
      <Container maxWidth="lg">
        <Box py={8} color="common.white">
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <Box>
                <Typography variant="h5" className={classes.description}>{content['description']}</Typography>
                <Typography variant="h5" className={classes.description}>
                  The mobile app is still in beta and will be available soon<sup>tm</sup>, but you can sign up as a beta
                  tester by sending and email
                  to <a href="mailto:hello@bux.ninja" style={{color: '#fff'}}>hello@bux.ninja</a>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box display="flex" height="100%" className={classes.actions}>
                <a href="#" style={{opacity: 0.7}} onClick={(e) => e.preventDefault() || alert('Soon!')}>
                  <img src="/images/logos/app-store.svg" alt="App store" height="60px"/>
                </a>
                <a href="#" style={{opacity: 0.7}} onClick={(e) => e.preventDefault() || alert('Soon!')}>
                  <img src="/images/logos/google-play.png" alt="Google play" height="88px"/>
                </a>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </section>
  );
}
