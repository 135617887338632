import React from 'react';
import {BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from "react-transition-group";

import ScrollToTop from "./components/ScrollToTop";
import IndexPage from './pages/Index.js';
import PrivacyPage from "./pages/Privacy";
import Page404 from "./pages/404";
import MobileWallet from "./pages/mobile-wallet";
import WalletService from "./pages/wallet-service";
import Login from "./pages/login";
import Terms from "./pages/terms";
import Signup from "./pages/signup";

export default function App() {
  return (
    <Router>
      <ScrollToTop>
        <AnimatedRoutes/>
      </ScrollToTop>
    </Router>
  );
}

const AnimatedRoutes = function() {
  let location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        classNames="fade"
        timeout={300}
      >
        <Switch>
          <Route exact path="/">
            <IndexPage/>
          </Route>
          <Route exact path="/mobile-wallet">
            <MobileWallet/>
          </Route>
          <Route exact path="/wallet-service">
            <WalletService/>
          </Route>
          <Route exact path="/privacy">
            <PrivacyPage/>
          </Route>
          <Route exact path="/terms">
            <Terms/>
          </Route>
          <Route exact path="/login">
            <Login/>
          </Route>
          <Route exact path="/signup">
            <Signup/>
          </Route>
          <Route path="*">
            <Page404/>
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  )
};
