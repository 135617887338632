import React, {useState} from 'react';
import Link from 'react-router-dom/Link'

import makeStyles from '@material-ui/core/styles/makeStyles';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import LayersIcon from '@material-ui/icons/Layers';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  toolbar: {
    minHeight: 70
  },
  brand: {
    lineHeight: 1,
    marginRight: 'auto'
  },
  link: {
    marginRight: theme.spacing(5),
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  primaryAction: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  iconWrapper: {
    minWidth: 40,
  },
  icon: {
    color: theme.palette.text.hint
  },
  drawerContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    width: 300,
  }
}));

export default function AppBarHeader() {
  const classes = useStyles();
  const history = useHistory();

  const [ open, setOpen ] = useState(false);

  return (
    <AppBar position="static" color="inherit">
      <Toolbar className={classes.toolbar}>
        <Link to="/" color="primary" underline="none" variant="h5" className={classes.brand}>
          <img src="/images/logo-light.png" alt="" height="42px"/>
        </Link>
        <Link to="/mobile-wallet" color="textPrimary" variant="body2" className={classes.link}>
          Mobile wallet
        </Link>
        <Link to="/wallet-service" color="textPrimary" variant="body2" className={classes.link}>
          Wallet service
        </Link>
        {/*
        <Link to="/privacy" color="textPrimary" variant="body2" className={classes.link}>
          Privacy
        </Link>
        <Link to="/terms" color="textPrimary" variant="body2" className={classes.link}>
          Terms
        </Link>
        <Link to="/login" color="textPrimary" variant="body2" className={classes.link}>
          Login
        </Link>
        <Button
          variant="contained"
          color="secondary"
          className={classes.primaryAction}
          onClick={() => {
            history.push('/signup');
          }}
        >
          Signup
        </Button>
        */}
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuButton}
          onClick={() => setOpen(true)}
        >
          <MenuIcon/>
        </IconButton>
      </Toolbar>
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        <div className={classes.drawerContainer}>
          <Box mb={1} ml={2} pb={2} border={1} borderTop={0} borderLeft={0} borderRight={0}
               borderColor="background.emphasis">
            <Link to="#" color="primary" underline="none" variant="h5" className={classes.linkBrand}>
              <img src="/images/logo-light.png" alt="" width="110"/>
            </Link>
          </Box>
          <List>
            <ListItem button key="Mobile wallet">
              <ListItemIcon className={classes.iconWrapper}>
                <LayersIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Mobile wallet"/>
            </ListItem>
            <ListItem button key="Wallet service">
              <ListItemIcon className={classes.iconWrapper}>
                <FilterHdrIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Wallet service"/>
            </ListItem>
            <ListItem button key="Privacy">
              <ListItemIcon className={classes.iconWrapper}>
                <DirectionsBusIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Privacy"/>
            </ListItem>
            <ListItem button key="Terms">
              <ListItemIcon className={classes.iconWrapper}>
                <NotificationImportantIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Terms"/>
            </ListItem>
            <ListItem button key="Login">
              <ListItemIcon className={classes.iconWrapper}>
                <NotificationImportantIcon className={classes.icon}/>
              </ListItemIcon>
              <ListItemText primary="Login"/>
            </ListItem>
          </List>
          <Box
            mt={1}
            ml={2}
            pt={3}
            border={1}
            borderBottom={0}
            borderLeft={0}
            borderRight={0}
            borderColor="background.emphasis"
          >
            <Button variant="contained" color="secondary" fullWidth>Signup</Button>
          </Box>
        </div>
      </Drawer>
    </AppBar>
  )
};
