import React from 'react';

import Page from "../components/Page";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundImage: 'url("/images/bg/pattern1.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
}));

export default function Terms() {
  const classes = useStyles();

  return (
    <Page>
      <section className={classes.section}>
        <Container maxWidth="lg" style={{display: 'flex', justifyContent: 'center'}}>
          <Box py={8} mb={3} color="common.white" maxWidth={800}>
            <Typography variant="body1" color="textSecondary" component="span">
              <h2>Terms of service mobile app</h2>
              <h3>Introduction</h3>
              <p>This document outlines the terms of service (“Terms”) for the Non-Custodial Bitcoin Mobile Wallet app
                Bux Ninja (the “App”). By using the App, you agree to be bound by these Terms. If you do not agree to
                these Terms, do not use the App.</p>

              <h3>App Purpose</h3>
              <p>The App is a non-custodial Bitcoin wallet that allows users to securely store, manage, and transact
                with their own Bitcoin. The App provides a user-friendly interface for users to access and manage their
                Bitcoin, but does not have control over or access to the users’ private keys.</p>

              <h3>User Responsibility</h3>
              <p>The App is designed to provide users with complete control over their Bitcoin. As such, users are
                solely responsible for the safekeeping of their private keys, and any transactions made using the App.
                Users must securely backup their private keys and store them in a safe place. The App will not be
                responsible for any loss of funds due to the loss, theft, or destruction of private keys.</p>

              <h3>User Accounts</h3>
              <p>Users are responsible for maintaining the confidentiality of their account information and must
                notify the App immediately in the event of any unauthorized use of their account.</p>

              <h3>Transactions</h3>
              <p>Users may use the App to send and receive Bitcoin. All transactions made using the App are final
                and cannot be reversed. Users must carefully verify the accuracy of all transaction information,
                including the recipient address and the amount, before confirming a transaction. The App will not be
                responsible for any errors or mistakes made by users in the course of a transaction.</p>

              <h3>Network Fees</h3>
              <p>The App uses the Bitcoin network to facilitate transactions. Network fees are required to process
                transactions and are paid to Bitcoin miners to confirm transactions. Users are responsible for
                paying network fees and must ensure that their transactions have sufficient fees to be processed
                in a timely manner.</p>

              <h3>Security</h3>
              <p>The App uses industry-standard security measures to protect user accounts and transactions.
                However, no method of transmission over the internet or electronic storage is 100% secure. As
                such, the App cannot guarantee the absolute security of user information and transactions. Users
                must take appropriate measures to secure their private keys and account information.</p>

              <h3>Third-Party Services</h3>
              <p>The App may use or integrate with third-party services, such as exchanges or payment
                processors, to provide additional functionality to users. The App is not responsible for the
                security or reliability of any third-party services and users must carefully review the terms
                of service and privacy policies of any third-party services before using them.</p>

              <h3>Warranty Disclaimer</h3>
              <p>The App is provided “as is” and without any warranty of any kind. The App makes no
                representations or warranties of any kind, express or implied, as to the operation of the
                App or the information, content, materials, or products included in the App.</p>

              <h3>Limitation of Liability</h3>
              <p>The App will not be liable for any damages of any kind arising from the use of the App,
                including but not limited to direct, indirect, incidental, punitive, and consequential
                damages.</p>

              <h3>Changes to Terms</h3>
              <p>The App reserves the right to modify these Terms at any time. Continued use of the App
                following any changes to these Terms constitutes acceptance of the revised Terms.</p>

              <h3>Governing Law</h3>
              <p>These Terms shall be governed by and construed in accordance with the laws of the
                jurisdiction in which the App is headquartered.</p>

              <h3>Contact Us</h3>
              <p>If you have any questions about this Privacy Policy, You can contact us:</p>
              <ul>
                <li>By email: <a href="mailto:hello@bux.ninja">hello@bux.ninja</a></li>
              </ul>
            </Typography>
          </Box>
        </Container>
      </section>
    </Page>
  )
};
