import React from 'react';
import { useHistory } from "react-router-dom";

import Page from "../components/Page";
import Header6 from '../components/headers/Header6';
import CallToAction2 from '../components/call-to-action/CallToAction2';
import HowItWorks2 from '../components/how-it-works/HowItWorks2';

import makeStyles from "@material-ui/core/styles/makeStyles";
import Pricing3 from "../components/pricing/Pricing3";

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    marginBottom: theme.spacing(3)
  }
}));

export default function WalletService() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Page>
      <section>
        <Header6
          content={{
            'header-p1': 'Bux Ninja',
            'header-p2': 'wallet service for your applications',
            description:
              'Focus on building your app, not on building a wallet infrastructure.',
            image: '/images/screen-console.png',
          }}
        />

        <CallToAction2
          content={{
            description:
              'The Bux Ninja wallet service allows you to be in control of all the user accounts of your application, while taking care of all aspects of sending and recieving Bitcoin transactions, and managing the utxo set of your users.',
            'primary-action': 'CONTACT US FOR MORE INFORMATION',
          }}
        />

        <HowItWorks2
          content={{
            'header-p1': 'Bux Ninja',
            'header-p2': 'is an SPV Bitcoin infrastructure solution.',
            description:
              'Bux Ninja is an SPV solution on the Bitcoin network, but unlike other SPV solutions, it is fully hosted and backed up. Next to this, no user identifiable information is stored on our servers, privacy is guaranteed.',
            'col1-header': 'Fully hosted infrastructure',
            'col2-header': 'Custom paymail support',
            'col3-header': 'Full support for the Bux Ninja mobile app',
          }}
        />

        <Pricing3
          content={{
            badge: 'The financial stuff',
            'header-p1': 'Wallet service',
            'header-p2': "pricing that doesn't break the bank",
            description:
              "We're here to serve, so let us know if there are other needs you might have.",
            '01_title': 'Personal',
            '01_price': '$0',
            '01_benefit1': '1 account',
            '01_benefit2': 'Fair use transactions',
            '01_benefit3': 'Free @bux.ninja paymail',
            '01_benefit4': 'Community support',
            '02_title': 'Developer',
            '02_price': '$49',
            '02_benefit1': 'Unlimited accounts',
            '02_benefit2': 'Fair use transactions',
            '02_benefit3': 'Custom domain paymail',
            '02_benefit4': 'Community support',
            '03_title': 'Professional',
            '03_price': '$249',
            '03_benefit1': 'Unlimited accounts',
            '03_benefit2': 'Fair use transactions',
            '03_benefit3': 'Custom domain paymail',
            '03_benefit4': 'Priority support',
            '04_price': '',
            '04_title': 'Enterprise',
            '04_suffix': 'Contact us for pricing',
            '04_benefit1': 'Unlimited accounts',
            '04_benefit2': 'Unlimited transactions',
            '04_benefit3': 'Custom domain paymails',
            '04_benefit4': 'Enterprise support / SLA',
          }}
        />
      </section>
    </Page>
  )
};
