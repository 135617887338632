import Footer3 from "./footers/Footer3";
import React from "react";
import AppBarHeader from "./headers/AppBarHeader";

export default function Page({children}) {
  return (
    <div className="page">
      <AppBarHeader/>
      {children}
      <Footer3
        content={{
          link1: 'Mobile wallet',
          link1_href: '/mobile-wallet',
          link2: 'Wallet service',
          link2_href: '/wallet-service',
          link3: 'Privacy',
          link3_href: '/privacy',
          link4: 'Terms',
          link4_href: '/terms',
          link5: 'Signup',
          link5_href: '/signup',
          link6: 'Login',
          link6_href: '/login',
          copy: '\u00a9 2023 Vilji Ve BV. All rights reserved.',
        }}
      />
    </div>
  )
};
